import React from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { Alert, Snackbar } from '@mui/material'

const AlertComponent = ({ alertComponent, setAlertComponent, alignment }) => {
  const { vertical, horizontal } = alignment
  const { open, severity, message } = alertComponent
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setAlertComponent({ open: false })}
    >
      <Alert
        onClose={() => setAlertComponent({ open: false })}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

AlertComponent.defaultProps = {
  alertComponent: { open: false, severity: 'success', message: '' },
  alignment: { vertical: 'bottom', horizontal: 'right' },
  setAlertComponent: noop,
}

AlertComponent.propTypes = {
  alertComponent: PropTypes.object,
  alignment: PropTypes.object,
  setAlertComponent: PropTypes.func,
}

export default AlertComponent
