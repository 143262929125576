import { getNewToken, renewToken } from 'src/Controllers/OAuth/OAuthApi'
// const fetch = require('fetch-retry')(global.fetch)

export const baseApiUrl = 'https://api.ffo-ca.bymobinteg.com'
// export const baseApiUrl = 'http://localhost:1234'

export const newApiUrl = 'https://node-api.ffo-ca.bymobinteg.com'
// export const newApiUrl = 'http://localhost:8080'
// export const testProductImage = 'https://node-api.ffo-ca.bymobinteg.com'
export const priceTestApiUrl = 'http://localhost:8080'

export const fetchRetry = require('fetch-retry')(global.fetch, {
  retryOn: [500, 503],
  retries: 5,
  retryDelay: (attempt) => Math.pow(2, attempt) * 1000,
})

export function getHttpHeaders(method) {
  let init = {
    method: method,
    headers: {
      Accept: '*/*',
      'Content-type': 'application/json',
    },
  }
  let token = localStorage.getItem('token')
  if (token) {
    init.headers.Authorization = 'Bearer ' + token
  }
  return init
}

export async function httpGetRequest(callbackMethod) {
  let response
  try {
    let response = await callbackMethod()
    try {
      if (response.ok) {
        return await response.json()
      }
      switch (response.status) {
        case 401:
          const renewResult = await getNewToken()
          if (!renewResult.ok) {
            localStorage.clear()
            window.location.reload()
            break
          }
          const renewInfo = await renewResult.json()
          if (!renewInfo?.encodedToken) {
            localStorage.clear()
            window.location.reload()
            break
          }
          localStorage.setItem('token', renewInfo.encodedToken)
          return httpGetRequest(callbackMethod)
        default:
          localStorage.clear()
          window.location.reload()
          break
      }
    } catch (e) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  } catch (err) {
    try {
      let renewResult = await renewToken()
      if (renewResult.Success) {
        response = await callbackMethod()
        if (response.ok) {
          return await response.json()
        } else {
          throw new Error('jwt expired')
        }
      }
    } catch (_err) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  }
}

export async function httpPostRequest(callbackMethod) {
  let response
  try {
    let response = await callbackMethod()

    try {
      return await response.json()
    } catch (e) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  } catch (err) {
    try {
      let renewResult = await renewToken()
      if (renewResult.Success) {
        response = await callbackMethod()
        if (response.ok) {
          return await response.json()
        } else {
          throw new Error('jwt expired')
        }
      }
    } catch (_err) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  }
}
